import React, { Component } from 'react'
import { translate } from 'react-i18next'
import { mapDispatchToProps } from '../../helpers/redux.helper'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Routes as AppRoutes } from '../../utils/constants'
import Logo from '../Logo'
import { resolutions } from '../../utils/constants'
import LangSelector from '../LangSelector'
import './index.sass'

class Navigation extends Component {
  state = { width: window.innerWidth }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render = () => {
    const {
      onHandleClick,
      actions: { changeModalVisibility, setModalType, setPlayingStatus, toggleVisibleAudioPlayer },
      t,
      lng,
    } = this.props
    const { width } = this.state

    const linkToPortfolio = lng === 'en' ? AppRoutes.WORK : `/${lng}${AppRoutes.WORK}`
    return (
      <nav className="navigation">
        {width > resolutions.small_desktop && (
          <NavLink to={`${lng === 'en' ? `${AppRoutes.MAIN}` : `/${lng}${AppRoutes.MAIN}`}`} onClick={onHandleClick}>
            <Logo />
          </NavLink>
        )}
        <div className="navigation-middle">
          <NavLink exact to={`${lng === 'en' ? '/' : `/${lng}`}`} onClick={onHandleClick}>
            {t('navigations.home')}
            <span className="nav-arrow"></span>
          </NavLink>
          <NavLink
            to={`${lng === 'en' ? `${AppRoutes.ABOUT}` : `/${lng}${AppRoutes.ABOUT}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.about')}
            <span className="nav-arrow"></span>
          </NavLink>
          {lng !== 'de' && (
            <NavLink
              className="highlight"
              to={`${lng === 'en' ? `${AppRoutes.SERVICES}` : `/${lng}${AppRoutes.SERVICES}`}`}
              onClick={onHandleClick}
            >
              {t('navigations.services')}
              <span className="nav-arrow"></span>
            </NavLink>
          )}
          <NavLink
            to={`${lng === 'en' ? `${AppRoutes.VOICES}` : `/${lng}${AppRoutes.VOICES}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.voices')}
            <span className="nav-arrow"></span>
          </NavLink>
          <NavLink
            to={`${lng === 'de' ? `/${lng}${AppRoutes.SHOWREELS}` : `${linkToPortfolio}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.portfolio')}
            <span className="nav-arrow"></span>
          </NavLink>
          <NavLink
            to={`${lng === 'en' ? `${AppRoutes.PAYMENTS}` : `/${lng}${AppRoutes.PAYMENTS}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.payments')}
            <span className="nav-arrow"></span>
          </NavLink>
          <NavLink to={`${lng === 'en' ? `${AppRoutes.PRICING}` : `/${lng}${AppRoutes.PRICING}`}`} onClick={onHandleClick}>
          {t('navigations.prices')}
          <span className="nav-arrow"></span>
        </NavLink>
          {/* <NavLink
            to={`${lng === 'en' ? `${AppRoutes.REVIEWS}` : `/${lng}${AppRoutes.REVIEWS}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.reviews')}
            <span className="nav-arrow"></span>
          </NavLink> */}
          <NavLink
            to={`${lng === 'en' ? `${AppRoutes.CHRONOMETER}` : `/${lng}${AppRoutes.CHRONOMETER}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.chronometer')}
            <span className="nav-arrow"></span>
          </NavLink>
          <NavLink
            to={`${lng === 'en' ? `${AppRoutes.CONTACTS}` : `/${lng}${AppRoutes.CONTACTS}`}`}
            onClick={onHandleClick}
          >
            {t('navigations.contacts')}
            <span className="nav-arrow"></span>
          </NavLink>
        </div>
        {/* <div className="clutchWrapper">
        <noindex>
          <a href="https://clutch.co/profile/universal-media-global-voice-over?utm_source=widget&utm_medium=1&utm_campaign=widget&utm_content=logo" rel='nofollow'>
            <div
              className="clutch-widget navigation-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="1"
              data-height="50"
              data-darkbg="1"
              data-clutchcompany-id="1580838"
            ></div>
          </a>
        </noindex>
        </div> */}
        
        <div className="navigation-bottom">
          <img src='/assets/award.svg' alt='award' width={'100'} style={{marginBottom: '1rem'}}/>
          <div className="navigation-bottom-wrapper">
            <LangSelector />
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                setModalType('privacyPolicy')
                changeModalVisibility(true)
                setPlayingStatus(false)
                toggleVisibleAudioPlayer(false)
              }}
            >
              {t('general.privacyPolicy')}
            </a>
          </div>
          <div className="payment-logos">
            <a href='https://www.instagram.com/unimedia.io/' target={'_blank'}>
            <img src="/assets/social/ic-instagram.svg" alt="instagram" />
            </a>
            <a href='https://www.facebook.com/unimedia.io/' target={'_blank'}>
            <img src="/assets/social/ic-facebook.svg" alt="facebook" />
            </a>
            <a href='https://www.linkedin.com/company/68529641' target={'_blank'}>
            <img src="/assets/social/ic-limkedin.svg" alt="linkedin" />
            </a>
          </div>
        </div>
      </nav>
    )
  }
}

export default translate('translations')(
  connect(
    null,
    mapDispatchToProps
  )(Navigation)
)
