export const AUTHORS_NAMES = {
    afrikaans: {
      male: [
        'Johannes', 'Stefanus', 'Pieter', 'Andries', 'Jacobus', 'Hendrik', 'Frans',
        'Barend', 'Riaan', 'Gerrit', 'Christiaan', 'Jan', 'Piet', 'Gustav', 'Marnus',
        'Willem', 'Kobus', 'Anton', 'Arno', 'Danie', 'Jacques', 'Hugo', 'Roelof',
        'Thys', 'Hennie', 'Koos', 'Charl', 'Jannie', 'Marius', 'Dawid', 'Frikkie',
        'Gideon', 'Bernard', 'Leander', 'Reinier', 'Werner', 'Lourens', 'Carel',
        'Gawie', 'Rudolph', 'Lukas', 'Ruan', 'Thinus', 'Frederik', 'Heinrich',
        'Eugene', 'Gerhard', 'Stefan',
      ],
      female: [
        'Elmarie', 'Petronella', 'Marlene', 'Susanna', 'Cornelia', 'Aletta',
        'Gertruida', 'Hendrina', 'Mina', 'Leandie', 'Anneline', 'Marga', 'Estelle',
        'Yolandi', 'Sonja', 'Magdel', 'Annetjie', 'Charmaine', 'Martie', 'Wilna',
        'Belinda', 'Erika', 'Mariska', 'Annelize', 'Aletta', 'Elise', 'Jacoline',
        'Zelda', 'Sanet', 'Lené', 'Izelle', 'Tersia', 'Anri', 'Lourika', 'Retha',
        'Fransie', 'Mariette', 'Lizette', 'Mareli', 'Inge', 'Karien', 'Liza',
        'Shanice', 'Ronel', 'Madelein', 'Johanna', 'Elize', 'Hester', 'Carien', 'Anli',
      ],
    },
    albanian: {
      male: [
        'Arben', 'Ilir', 'Besnik', 'Dritan', 'Agron', 'Artan', 'Sokol', 'Genti',
        'Fatos', 'Adem', 'Luan', 'Bujar', 'Shpend', 'Fatmir', 'Valon', 'Ardit',
        'Naim', 'Kreshnik', 'Driton', 'Altin', 'Ylli', 'Gentian', 'Mentor', 'Jetmir',
        'Kujtim', 'Afrim', 'Lirim', 'Besmir', 'Dritëro', 'Shkëlzen', 'Arian',
        'Bashkim', 'Agim', 'Alban', 'Avni', 'Bekim', 'Flamur', 'Genc', 'Gezim',
        'Herolind', 'Lindon', 'Reshat', 'Skënder', 'Tefik', 'Valdrin', 'Xhevdet',
        'Zamir', 'Edmond', 'Endrit',
      ],
      female: [
        'Arta', 'Besa', 'Lule', 'Alketa', 'Teuta', 'Miranda', 'Valbona', 'Lumturi',
        'Gentiana', 'Lira', 'Flutura', 'Gerta', 'Afërdita', 'Ermira', 'Ganimete',
        'Elira', 'Mimoza', 'Shpresa', 'Dafina', 'Edlira', 'Fjona', 'Dorina',
        'Manjola', 'Besjana', 'Inesa', 'Diellza', 'Albana', 'Donika', 'Nora',
        'Yllka', 'Bardha', 'Enkelejda', 'Adelina', 'Merita', 'Fitore', 'Rudina',
        'Valentina', 'Kaltrina', 'Vjollca', 'Ariana', 'Fjoralba', 'Xhensila',
        'Valmira', 'Aferdita', 'Rozafa', 'Edona', 'Anila', 'Erla', 'Luljeta', 'Zamira',
      ],
    },
    amazigh: {
      male: [
        'Idir', 'Tahar', 'Massinissa', 'Yuba', 'Tafsut', 'Khaled', 'Yannis',
        'Aksel', 'Mokhtar', 'Aziz', 'Amghar', 'Said', 'Karim', 'Mohand', 'Tifawt',
        'Izem', 'Rabah', 'Hassan', 'Ismail', 'Fellah', 'Younes', 'Ammar', 'Tarik',
        'Rachid', 'Kamel', 'Slimane', 'Brahim', 'Rida',
      ],
      female: [
        'Tafsut', 'Assa', 'Lalla', 'Nabila', 'Rim', 'Tasnim', 'Yasmin', 'Meriem',
        'Zineb', 'Ines', 'Malika', 'Kenza', 'Tiziri', 'Najat', 'Tala', 'Fadma',
        'Aicha', 'Tamazgha', 'Asma', 'Farida', 'Aziza', 'Fatima', 'Noura', 'Imane',
        'Rakia', 'Amani', 'Laila', 'Nouara', 'Zohra', 'Yamina',
      ],
    },
    'american-english': {
      male: [
        'Jackson', 'Landon', 'Caleb', 'Wyatt', 'Chase', 'Hunter', 'Austin',
        'Gavin', 'Colton', 'Tyler', 'Brandon', 'Mason', 'Logan', 'Blake', 'Cooper',
        'Ryan', 'Nathan', 'Dylan', 'Carter', 'Ethan', 'Henry', 'Liam', 'Owen',
        'Zachary', 'Cameron', 'Lucas', 'James', 'Justin', 'Oliver', 'Miles',
      ],
      female: [
        'Madison', 'Hailey', 'Brooklyn', 'Savannah', 'Chloe', 'Ashley', 'Brianna',
        'Samantha', 'Avery', 'Taylor', 'Morgan', 'Ella', 'Zoe', 'Kayla', 'Mia',
        'Allison', 'Hannah', 'Isabella', 'Grace', 'Lily', 'Aubrey', 'Sophie',
        'Amelia', 'Sophia', 'Kaitlyn', 'Alyssa', 'Bailey', 'Natalie', 'Jasmine', 'Autumn',
      ],
    },
    amharic: {
      male: [
        'Abebe', 'Kebede', 'Alemayehu', 'Tsegaye', 'Bekele', 'Fikru', 'Dawit',
        'Tadesse', 'Mekonnen', 'Tesfaye', 'Haile', 'Hailu', 'Yared', 'Tamiru',
        'Sisay', 'Kebret', 'Muluken', 'Getachew', 'Mulugeta', 'Tilahun', 'Mengistu',
        'Tefera', 'Girma', 'Endale', 'Mesfin', 'Abiy', 'Dereje', 'Amanuel', 'Fikadu', 'Berhanu',
      ],
      female: [
        'Almaz', 'Tigist', 'Zewditu', 'Mulu', 'Aster', 'Birhan', 'Tsehay', 'Yalem',
        'Marta', 'Wubit', 'Meaza', 'Hirut', 'Selam', 'Eyerusalem', 'Lemlem',
        'Zenebech', 'Abeba', 'Gete', 'Meseret', 'Alem', 'Saron', 'Tsehaynesh',
        'Mahlet', 'Bezawit', 'Banchayehu', 'Eleni', 'Yordanos', 'Desta', 'Tadesech', 'Hana',
      ],
    },
    arabic: {
      male: [
        'Omar', 'Ahmed', 'Khaled', 'Youssef', 'Ali', 'Faisal', 'Tariq', 'Hassan',
        'Mahmoud', 'Nasser', 'Zayd', 'Mustafa', 'Jamal', 'Rami', 'Fadi', 'Salim',
        'Abdul', 'Nabil', 'Rashid', 'Kareem', 'Adnan', 'Saif', 'Hatem', 'Fouad',
        'Amir', 'Farouk', 'Samir', 'Walid', 'Imran', 'Hamza',
      ],
      female: [
        'Fatima', 'Aisha', 'Noura', 'Layla', 'Amira', 'Mariam', 'Zahra', 'Hiba',
        'Sara', 'Rania', 'Yasmin', 'Asma', 'Leila', 'Nadine', 'Samar', 'Nada',
        'Salma', 'Hanan', 'Safa', 'Iman', 'Dina', 'Noor', 'Hajar', 'Reem', 'Hind',
        'Aya', 'Lamia', 'Khadija', 'Maha', 'Lina',
      ],
    },
    armenian: {
      male: [
        'Hovhannes', 'Armen', 'Gagik', 'Tigran', 'Sarkis', 'Levon', 'Hayk',
        'Aram', 'Vahagn', 'Karekin', 'Artur', 'Samvel', 'Gegham', 'Serop',
        'Shant', 'Vazgen', 'Narek', 'Khoren', 'Manuk', 'Ara', 'Gevorg',
        'Harutyun', 'Mher', 'Vardan', 'Hagop', 'Khachatur', 'Ashot',
        'Raffi', 'Mikayel', 'Smbat',
      ],
      female: [
        'Anahit', 'Mariam', 'Siranush', 'Hasmik', 'Nune', 'Armenuhi', 'Gohar',
        'Tatevik', 'Shoghik', 'Naira', 'Haykanush', 'Karine', 'Arusyak',
        'Lilit', 'Zaruhi', 'Arpi', 'Meline', 'Hripsime', 'Varduhi', 'Maral',
        'Sona', 'Arax', 'Lusine', 'Gayane', 'Tamar', 'Elena', 'Sose',
        'Arpine', 'Margarit', 'Yeva',
      ],
    },
    'australian-english': {
      male: [
        'Ethan', 'Noah', 'Lachlan', 'Cooper', 'James', 'Harrison', 'Jackson',
        'Joshua', 'Oliver', 'Xavier', 'William', 'Flynn', 'Samuel', 'Mason',
        'Blake', 'Zachary', 'Levi', 'Ryan', 'Mitchell', 'Thomas', 'Connor',
        'Callum', 'Aiden', 'Hunter', 'Nathan', 'Jacob', 'Isaac', 'Charlie',
        'Sebastian', 'Alexander',
      ],
      female: [
        'Charlotte', 'Mia', 'Isla', 'Harper', 'Sienna', 'Willow', 'Chloe',
        'Scarlett', 'Ava', 'Matilda', 'Ruby', 'Hannah', 'Isabella', 'Grace',
        'Zoe', 'Lily', 'Olivia', 'Emily', 'Ellie', 'Sophia', 'Evie',
        'Mackenzie', 'Amelia', 'Aria', 'Georgia', 'Summer', 'Eva', 'Abigail',
        'Paige', 'Lucy',
      ],
    },
    azeri: {
      male: [
        'Elvin', 'Rauf', 'Samir', 'Ramin', 'Adil', 'Eldar', 'Tamerlan',
        'Nazim', 'Fuad', 'Tural', 'Ilgar', 'Kamran', 'Farhad', 'Nurlan',
        'Zaur', 'Rashad', 'Javid', 'Orkhan', 'Azad', 'Anar', 'Vugar',
        'Jamal', 'Teymur', 'Vusal', 'Suleyman', 'Elchin', 'Babek',
        'Emin', 'Ibrahim', 'Tofig',
      ],
      female: [
        'Gunel', 'Aygun', 'Nigar', 'Sevda', 'Narmin', 'Sabina', 'Kamilla',
        'Lale', 'Ulker', 'Zarina', 'Aida', 'Lamiya', 'Aysel', 'Samira',
        'Konul', 'Arzu', 'Mehriban', 'Nermin', 'Nazrin', 'Jale', 'Tamilla',
        'Lala', 'Rena', 'Sevil', 'Shafa', 'Aynur', 'Rashida', 'Dilara',
        'Gulnar', 'Zamira',
      ],
    },
    belarusian: {
      male: [
        'Alyaksandr', 'Siarhei', 'Dzmitry', 'Paval', 'Andrei', 'Yury',
        'Viktar', 'Mikalai', 'Ihar', 'Ryhor', 'Vasil', 'Uladzimir',
        'Pyotr', 'Stanislav', 'Maksim', 'Valery', 'Henadz', 'Aliaksei',
        'Vladimir', 'Mikhail', 'Raman', 'Yauheni', 'Ivan', 'Artsiom',
        'Stepan', 'Evgeni', 'Antoni', 'Lev', 'Vadim',
      ],
      female: [
        'Hanna', 'Natallia', 'Volha', 'Maryia', 'Iryna', 'Yuliya',
        'Darya', 'Katsiaryna', 'Alena', 'Tatsiana', 'Nadzeya', 'Zhanna', 'Ludmila',
        'Svetlana', 'Viktoryia', 'Yulia', 'Maria', 'Nina', 'Lyudmila', 'Anastasiya',
        'Valentina', 'Galina', 'Veranika', 'Alina', 'Yana', 'Alesya', 'Olga', 'Polina',
        'Sviatlana', 'Karyna',
    ],
    },
        bengali: {
          male: [
            'Arif', 'Rahman', 'Anis', 'Habib', 'Masud', 'Ibrahim', 'Kamrul',
            'Nazmul', 'Sumon', 'Rakib', 'Shakil', 'Rashid', 'Farid', 'Hassan',
            'Mehedi', 'Jamal', 'Fahim', 'Saiful', 'Rafiq', 'Tareq', 'Shafik',
            'Ashraf', 'Adnan', 'Asif', 'Naim', 'Imran', 'Mahmud', 'Sayeed',
            'Amin', 'Hasan',
          ],
          female: [
            'Rupa', 'Mithila', 'Sultana', 'Parveen', 'Ayesha', 'Sumaiya',
            'Nabila', 'Nargis', 'Sadia', 'Farzana', 'Nasrin', 'Shamima',
            'Tahmina', 'Fatema', 'Jannat', 'Salma', 'Rubina', 'Rehana',
            'Tasneem', 'Asma', 'Mahmuda', 'Sujata', 'Shirin', 'Laila',
            'Nusrat', 'Sabina', 'Mumtaz', 'Khadija', 'Taslima', 'Nazma',
          ],
        },
        bosnian: {
          male: [
            'Amir', 'Haris', 'Meho', 'Adem', 'Elvir', 'Samir', 'Enes',
            'Mirsad', 'Dino', 'Armin', 'Sead', 'Jasmin', 'Tarik', 'Senad',
            'Emir', 'Fikret', 'Damir', 'Vedad', 'Nedim', 'Esad', 'Edis',
            'Sabahudin', 'Adis', 'Edin', 'Zlatko', 'Kemal', 'Zijad',
            'Senin', 'Ismir', 'Bakir',
          ],
          female: [
            'Amra', 'Jasmina', 'Selma', 'Zehra', 'Emina', 'Adna', 'Lejla',
            'Aida', 'Sabina', 'Amina', 'Elma', 'Edina', 'Dzenita', 'Azra',
            'Mirsada', 'Samira', 'Nermina', 'Alma', 'Hana', 'Sanela',
            'Esma', 'Zulma', 'Minela', 'Ajla', 'Belma', 'Denisa', 'Ilma',
            'Lina', 'Emira', 'Fatima',
          ],
        },
        'brazilian-portuguese': {
          male: [
            'Thiago', 'Rafael', 'Mateus', 'Guilherme', 'Bruno', 'Lucas',
            'Eduardo', 'Carlos', 'Paulo', 'Leonardo', 'Fernando', 'Gabriel',
            'Ricardo', 'Caio', 'Felipe', 'André', 'Diego', 'Murilo',
            'Victor', 'Rodrigo', 'João', 'Henrique', 'Vitor', 'Daniel',
            'Pedro', 'Marcelo', 'Rogério', 'Renan', 'Roberto', 'Alexandre',
          ],
          female: [
            'Camila', 'Mariana', 'Beatriz', 'Fernanda', 'Larissa', 'Carolina',
            'Gabriela', 'Juliana', 'Patrícia', 'Aline', 'Bianca', 'Isabela',
            'Rafaela', 'Ana', 'Lúcia', 'Catarina', 'Júlia', 'Vanessa',
            'Daniele', 'Priscila', 'Adriana', 'Bruna', 'Thaís', 'Sílvia',
            'Alessandra', 'Natália', 'Débora', 'Amanda', 'Flávia', 'Cláudia',
          ],
        },
        'british-english': {
          male: [
            'Alfie', 'Harry', 'Oliver', 'George', 'Archie', 'James', 'William',
            'Henry', 'Freddie', 'Alexander', 'Charlie', 'Leo', 'Arthur',
            'Thomas', 'Jack', 'Lucas', 'Oscar', 'Liam', 'Finley', 'Ethan',
            'Joshua', 'Jacob', 'Samuel', 'Max', 'Edward', 'Joseph',
            'Daniel', 'Sebastian', 'Harrison', 'Isaac',
          ],
          female: [
            'Isla', 'Ava', 'Lily', 'Poppy', 'Sophia', 'Emily', 'Mia',
            'Isabella', 'Charlotte', 'Freya', 'Amelia', 'Jessica', 'Florence',
            'Alice', 'Olivia', 'Sophie', 'Ruby', 'Grace', 'Ivy', 'Daisy',
            'Holly', 'Megan', 'Ella', 'Millie', 'Matilda', 'Phoebe',
            'Chloe', 'Imogen', 'Evie', 'Evelyn',
          ],
      },
      bulgarian: {
    male: [
      'Borislav', 'Ivan', 'Kiril', 'Dimitar', 'Georgi', 'Stefan', 'Todor',
      'Nikolay', 'Boyan', 'Hristo', 'Vasil', 'Stanislav', 'Petar',
      'Simeon', 'Aleksandar', 'Stoyan', 'Mihail', 'Lazar', 'Zlatan',
      'Nikola', 'Velislav', 'Rumen', 'Tihomir', 'Kaloyan', 'Asen',
      'Yordan', 'Lyuben', 'Lyubomir', 'Radostin', 'Stanimir',
    ],
    female: [
      'Mariya', 'Desislava', 'Ivanka', 'Yana', 'Tsvetana', 'Katya',
      'Valentina', 'Borislava', 'Milena', 'Stefka', 'Elena', 'Petya',
      'Rositsa', 'Stanislava', 'Veneta', 'Teodora', 'Radka', 'Iveta',
      'Nadezhda', 'Kristina', 'Elitsa', 'Galina', 'Slavka', 'Zhivka',
      'Nikol', 'Antoaneta', 'Kalina', 'Violeta', 'Albena', 'Siyana',
    ],
  },
  burmese: {
    male: [
      'Aung', 'Kyaw', 'Than', 'Hlaing', 'Min', 'Htun', 'Zaw',
      'Myo', 'Win', 'Tun', 'Lwin', 'Ko', 'Soe', 'Nyein',
      'Maung', 'Linn', 'Phyo', 'Thu', 'Zayar', 'Khin',
      'Zeya', 'Ye', 'Myint', 'Thuya', 'Nay', 'Zayar',
      'Htut', 'Shwe', 'Zeya', 'Yan',
    ],
    female: [
      'Nwe', 'Aye', 'Thazin', 'Mya', 'Soe', 'Shwe', 'Hnin',
      'Hla', 'Phyu', 'Mon', 'San', 'Win', 'Khin', 'Htwe',
      'Thiri', 'Sandar', 'Ei', 'Htun', 'Khine', 'Sanda',
      'Yin', 'Myat', 'Khaing', 'La', 'Moe', 'Shin',
      'Yadana', 'Pan', 'Swe',
    ],
  },
  'canadian-english': {
    male: [
      'Liam', 'Connor', 'Logan', 'Owen', 'Ethan', 'Mason',
      'Noah', 'Lucas', 'Nathan', 'Carter', 'Aiden', 'Jacob',
      'William', 'Alexander', 'Samuel', 'Elijah', 'Jackson',
      'Benjamin', 'Michael', 'Ryan', 'Matthew', 'James',
      'Tyler', 'Jayden', 'Dylan', 'David', 'Joshua', 'Luke',
      'Oliver', 'Jack',
    ],
    female: [
      'Avery', 'Sophia', 'Madison', 'Olivia', 'Isabella', 'Emma',
      'Ella', 'Mia', 'Chloe', 'Grace', 'Hannah', 'Riley',
      'Abigail', 'Sarah', 'Sophie', 'Lily', 'Charlotte',
      'Emily', 'Zoe', 'Addison', 'Brooklyn', 'Hailey',
      'Julia', 'Mackenzie', 'Kaitlyn', 'Lillian', 'Samantha',
      'Audrey', 'Victoria', 'Leah',
    ],
  },
  'canadian-french': {
    male: [
      'Étienne', 'Gabriel', 'Alexandre', 'Mathieu', 'Louis',
      'François', 'Olivier', 'Philippe', 'Jean', 'Xavier',
      'Nicolas', 'Antoine', 'Sébastien', 'Yannick', 'Jacques',
      'Michel', 'Yves', 'Rémi', 'Stéphane', 'Laurent',
      'Marc', 'Paul', 'Vincent', 'Jérôme', 'Éric',
      'Thierry', 'Claude', 'Denis', 'Julien', 'Pierre',
    ],
    female: [
      'Isabelle', 'Élise', 'Marie', 'Geneviève', 'Chantal',
      'Nathalie', 'Julie', 'Caroline', 'Émilie', 'Sophie',
      'Valérie', 'Véronique', 'Laurence', 'Manon', 'Christine',
      'Amélie', 'Camille', 'Audrey', 'Claudine', 'Josée',
      'Mélanie', 'Anne', 'Florence', 'Catherine', 'Pascale',
      'Hélène', 'Martine', 'Roxane', 'Claire', 'Évelyne',
    ],
  },
  catalan: {
    male: [
      'Jordi', 'Oriol', 'Pere', 'Francesc', 'Marc', 'Andreu',
      'Albert', 'Joan', 'Gerard', 'Xavier', 'Carles', 'Sergi',
      'Josep', 'Ramon', 'Ricard', 'Ferran', 'Guillem', 'Arnau',
      'Miquel', 'Pol', 'Enric', 'Esteve', 'Adrià', 'Toni',
      'Aleix', 'Rafel', 'Bernat', 'David', 'Pau', 'Roger',
    ],
    female: [
      'Marta', 'Aina', 'Laia', 'Núria', 'Meritxell', 'Gemma',
      'Roser', 'Sílvia', 'Eulàlia', 'Carme', 'Anna',
      'Montserrat', 'Maria', 'Elena', 'Teresa', 'Imma',
      'Sònia', 'Neus', 'Adriana', 'Esther', 'Clàudia',
      'Isabel', 'Pilar', 'Mariona', 'Carolina', 'Natàlia',
      'Lídia', 'Dolors', 'Berta', 'Mireia',
    ],
  },
  'chinese-cantonese': {
    male: [
      'Wai-Lam', 'Cheung', 'Ming', 'Yee', 'Lok', 'Hau',
      'Man', 'Wing', 'Tung', 'Hoi', 'Yan', 'Chun',
      'Lok-Kei', 'Chi-Wai', 'Kin', 'Kwan', 'Kong',
      'Hin', 'Chiu', 'Ho', 'Ping', 'Siu', 'Fung',
      'Lam', 'Shun', 'Tin', 'Wong', 'Leung',
      'Chun-Wai', 'Wai-Hong',
    ],
    female: [
      'Mei', 'Ling', 'Ching', 'Yan', 'Wai', 'Fong',
      'Suk', 'Kam', 'Yuen', 'Ting', 'Yu', 'Po',
      'Man', 'Sau', 'Lok-Yee', 'Wai-Yee', 'Mui',
      'Ying', 'Ting-Ting', 'Ngan', 'Hing', 'Pui',
      'Ka-Lai', 'Kwok', 'Fai', 'Kam-Ling', 'Fung-Yi',
      'Hoi-Yee', 'On', 'Siu-Man',
    ],
  },
  'chinese-mandarin': {
    male: [
      'Wei', 'Yuan', 'Jun', 'Liang', 'Dong', 'Chen', 'Hao', 'Tao', 'Jie', 'Ming',
      'Yang', 'Tian', 'Zhao', 'Huan', 'Bin', 'Lei', 'Gang', 'Xiang', 'Qiang', 'Cheng',
      'Rui', 'Feng', 'Xin', 'Shu', 'Bo', 'Shen', 'Wu', 'Hong', 'Lu', 'Ji',
    ],
    female: [
      'Xia', 'Ying', 'Fang', 'Mei', 'Lan', 'Ting', 'Li', 'Yan', 'Qin', 'Yue',
      'Hua', 'Rong', 'Ping', 'Juan', 'Xiu', 'Jing', 'Qiao', 'Min', 'Ling', 'Yuan',
      'Zhen', 'Lina', 'Shan', 'Xiaomei', 'Ai', 'Ning', 'Ya', 'Zhi', 'Hui', 'Yu',
    ],
  },
  croatian: {
    male: [
      'Luka', 'Karlo', 'Matej', 'Ivan', 'Petar', 'Tomislav', 'Nikola', 'Andrija', 'Ante',
      'Josip', 'Davor', 'Filip', 'Tihomir', 'Stjepan', 'Damir', 'Vedran', 'Marko',
      'Zoran', 'Zvonimir', 'Franjo', 'Goran', 'Juraj', 'Slaven', 'Mislav', 'Božidar',
      'Vinko', 'Hrvoje', 'Mihael', 'Dalibor', 'Branimir',
    ],
    female: [
      'Ana', 'Ivana', 'Mia', 'Lana', 'Marija', 'Martina', 'Katarina', 'Petra', 'Lucija',
      'Nina', 'Maja', 'Jelena', 'Kristina', 'Tamara', 'Mirjana', 'Snježana', 'Zrinka',
      'Anđela', 'Ines', 'Edita', 'Gordana', 'Milena', 'Nikolina', 'Sandra', 'Tatjana',
      'Dubravka', 'Doris', 'Dragica', 'Nada', 'Višnja',
    ],
  },
  czech: {
    male: [
      'Jan', 'Jakub', 'Tomáš', 'Lukáš', 'Vojtěch', 'Matěj', 'Michal', 'Petr', 'Marek',
      'Martin', 'Ondřej', 'Filip', 'Šimon', 'Adam', 'Václav', 'Jiří', 'Josef', 'Aleš',
      'Radek', 'Kamil', 'Zdeněk', 'Lubomír', 'Roman', 'Jaroslav', 'Milan', 'Libor',
      'Miroslav', 'Vlastimil', 'Antonín', 'Rostislav',
    ],
    female: [
      'Tereza', 'Eliška', 'Anna', 'Karolína', 'Kristýna', 'Věra', 'Lucie', 'Adéla',
      'Veronika', 'Kateřina', 'Hana', 'Barbora', 'Lenka', 'Jana', 'Ivana', 'Marie',
      'Monika', 'Markéta', 'Zuzana', 'Petra', 'Alena', 'Šárka', 'Olga', 'Michaela',
      'Natálie', 'Klára', 'Magdalena', 'Blanka', 'Gabriela', 'Martina',
    ],
  },
  danish: {
    male: [
      'Mikkel', 'Anders', 'Mads', 'Søren', 'Nikolaj', 'Christian', 'Emil', 'Rasmus',
      'Frederik', 'Magnus', 'Jesper', 'Lars', 'Kasper', 'Oliver', 'Jonas', 'Henrik',
      'Benjamin', 'Peter', 'Andreas', 'Thomas', 'Jakob', 'Jens', 'Mathias', 'Simon',
      'Niels', 'Daniel', 'Viggo', 'Karl', 'Esben', 'William',
    ],
    female: [
      'Freja', 'Emma', 'Ida', 'Mathilde', 'Laura', 'Sofie', 'Clara', 'Anna', 'Julie',
      'Marie', 'Nanna', 'Caroline', 'Amalie', 'Alma', 'Signe', 'Mette', 'Astrid',
      'Rosa', 'Agnes', 'Louise', 'Katrine', 'Elin', 'Maja', 'Mille', 'Frida',
      'Johanne', 'Sarah', 'Birgitte', 'Eva', 'Kirstine',
    ],
  },
  dari: {
    male: [
      'Ahmad', 'Ghulam', 'Omar', 'Qasim', 'Karim', 'Rashid', 'Farid', 'Habib', 'Zahir',
      'Hussain', 'Faisal', 'Hameed', 'Zaman', 'Najib', 'Khalid', 'Ibrahim', 'Latif',
      'Sami', 'Salim', 'Jamil', 'Hassan', 'Nemat', 'Fazal', 'Sardar', 'Rafiq', 'Arif',
      'Jalal', 'Shafiq', 'Nawaz', 'Amin',
    ],
    female: [
      'Zainab', 'Amina', 'Fatima', 'Razia', 'Shabnam', 'Pari', 'Maryam', 'Nargis',
      'Nadia', 'Rokhshana', 'Farzana', 'Nilofar', 'Sitara', 'Laila', 'Gul', 'Nasrin',
      'Shirin', 'Farah', 'Zarina', 'Parween', 'Fahima', 'Sabera', 'Halima', 'Sahar',
      'Nelofer', 'Khadija', 'Sadaf', 'Malalai', 'Aisha', 'Mina',
    ],
  },
  dutch: {
    male: [
      'Jeroen', 'Bart', 'Willem', 'Hendrik', 'Daan', 'Pieter', 'Maarten', 'Rik', 'Arjen',
      'Koen', 'Lars', 'Sander', 'Thijs', 'Niels', 'Bas', 'Dirk', 'Jasper', 'Luc', 'Floris',
      'Stijn', 'Ruben', 'Pepijn', 'Kees', 'Gijs', 'Timo', 'Bram', 'Milan', 'Hugo', 'Luuk',
      'Matthijs',
    ],
    female: [
      'Sanne', 'Femke', 'Lieke', 'Marieke', 'Roos', 'Esmée', 'Anouk', 'Ilse', 'Janneke',
      'Inge', 'Lotte', 'Maud', 'Sophie', 'Fleur', 'Merel', 'Isa', 'Saskia', 'Hanneke',
      'Daphne', 'Yara', 'Elise', 'Myrthe', 'Lisanne', 'Tessa', 'Esmee', 'Lara', 'Romy',
      'Nina', 'Jolien', 'Eva',
    ],
  },
  estonian: {
    male: [
      'Marko', 'Toomas', 'Kalle', 'Raimo', 'Mart', 'Tarmo', 'Ragnar', 'Indrek', 'Erki',
      'Juhan', 'Peeter', 'Taavi', 'Aivar', 'Heiki', 'Priit', 'Kalev', 'Erik', 'Rainer',
      'Vahur', 'Meelis', 'Raivo', 'Madis', 'Karl', 'Siim', 'Eero', 'Raul', 'Kaupo',
      'Jaanus', 'Veiko', 'Tõnu',
    ],
    female: [
      'Kadri', 'Liis', 'Anneli', 'Piret', 'Mari', 'Evelin', 'Maarja', 'Kadi', 'Katrin',
      'Triin', 'Karin', 'Laura', 'Marika', 'Heli', 'Kristi', 'Kersti', 'Siret', 'Maarika',
      'Riina', 'Maie', 'Anne', 'Tiina', 'Sirje', 'Kai', 'Inga', 'Laine', 'Reet', 'Aili',
      'Rita', 'Liina',
    ],
  },
  farsi: {
    male: [
      'Ali', 'Reza', 'Hossein', 'Mohammad', 'Mehdi', 'Saeed', 'Omid', 'Bahram', 'Kaveh',
      'Peyman', 'Majid', 'Ehsan', 'Farhad', 'Amir', 'Shahram', 'Bijan', 'Navid', 'Hamid',
      'Ramin', 'Arash', 'Vahid', 'Morteza', 'Nima', 'Kamran', 'Parviz', 'Farshad', 'Milad',
      'Kian', 'Behzad', 'Shayan',
    ],
    female: [
      'Maryam', 'Fatemeh', 'Zahra', 'Sara', 'Leila', 'Neda', 'Arezoo', 'Parisa', 'Negin',
      'Roxana', 'Shirin', 'Mina', 'Ziba', 'Taraneh', 'Shahla', 'Golnaz', 'Niloofar',
      'Mahsa', 'Ladan', 'Fereshteh', 'Shadi', 'Azadeh', 'Yasmin', 'Mitra', 'Elham',
      'Homa', 'Setareh', 'Nasim', 'Shabnam', 'Farnaz',
    ],
  },
  filipino: {
    male: [
      'Juan', 'Carlos', 'Jose', 'Rafael', 'Miguel', 'Gabriel', 'Emmanuel', 'Andres',
      'Fernando', 'Ricardo', 'Alfredo', 'Domingo', 'Luis', 'Antonio', 'Jorge', 'Manuel',
      'Raul', 'Enrico', 'Benito', 'Alberto', 'Teodoro', 'Salvador', 'Eduardo', 'Pablo',
      'Marcelo', 'Arturo', 'Javier', 'Gregorio', 'Leandro', 'Nicolas',
    ],
    female: [
      'Maria', 'Luisa', 'Carmen', 'Isabel', 'Rosario', 'Angela', 'Veronica', 'Patricia',
      'Sofia', 'Teresa', 'Felicia', 'Pilar', 'Adela', 'Dolores', 'Gabriela', 'Victoria',
      'Jacinta', 'Elena', 'Mercedes', 'Rosa', 'Gloria', 'Beatriz', 'Consuelo', 'Catalina',
      'Esperanza', 'Luz', 'Estrella', 'Juana', 'Mariana', 'Silvia',
    ],
  },
  finnish: {
    male: [
      'Jari', 'Mikko', 'Jussi', 'Pekka', 'Antti', 'Kari', 'Janne', 'Heikki', 'Sami',
      'Timo', 'Marko', 'Risto', 'Juha', 'Jukka', 'Eero', 'Petri', 'Ville', 'Mika',
      'Arto', 'Lauri', 'Olli', 'Matti', 'Vesa', 'Jouni', 'Harri', 'Tapio', 'Erkki',
      'Ari', 'Ilkka', 'Paavo',
    ],
    female: [
      'Riikka', 'Sari', 'Anneli', 'Päivi', 'Marja', 'Tuula', 'Laura', 'Pirjo', 'Kirsi',
      'Satu', 'Maija', 'Helena', 'Anni', 'Eeva', 'Leena', 'Liisa', 'Elina', 'Kati',
      'Marjaana', 'Susanna', 'Tiina', 'Johanna', 'Tanja', 'Kaarina', 'Minna', 'Outi',
      'Hannele', 'Hanna', 'Sirpa', 'Kirsti',
    ],
  },
  flemish: {
    male: [
      'Gert', 'Bart', 'Karel', 'Pieter', 'Stijn', 'Wim', 'Jeroen', 'Tom', 'Maarten',
      'Koen', 'Geert', 'Bram', 'Jan', 'Rik', 'Bert', 'Frederik', 'Hans', 'Ruben',
      'Thijs', 'Daan', 'Sven', 'Johan', 'Kevin', 'Bavo', 'Dirk', 'Laurens', 'Wouter',
      'Sam', 'Tuur', 'Marnix',
    ],
    female: [
      'Liesbeth', 'Anke', 'Elke', 'Inge', 'Mieke', 'Leen', 'Tine', 'Sofie', 'Els', 'An',
      'Veerle', 'Joke', 'Sigrid', 'Lies', 'Ine', 'Heidi', 'Ilse', 'Evi', 'Neeltje',
      'Marijke', 'Katleen', 'Lore', 'Lien', 'Caroline', 'Karen', 'Tessa', 'Liselotte',
      'Katrien', 'Femke', 'Annelies',
    ],
  },
  french: {
    male: [
      'Julien', 'Thomas', 'Mathieu', 'Nicolas', 'Alexandre', 'Florian', 'Antoine',
      'Benjamin', 'Guillaume', 'Lucas', 'Vincent', 'Sébastien', 'Pierre', 'Thibault',
      'Adrien', 'Damien', 'Olivier', 'Romain', 'Cédric', 'Arnaud', 'Hugo', 'François',
      'Rémi', 'Jérémy', 'Jean', 'Paul', 'Simon', 'Rodolphe', 'Gautier', 'Loïc',
    ],
    female: [
      'Marie', 'Camille', 'Sophie', 'Juliette', 'Claire', 'Chloé', 'Charlotte', 'Julie',
      'Laura', 'Emma', 'Alice', 'Anaïs', 'Léa', 'Gabrielle', 'Élodie', 'Sarah', 'Manon',
      'Lina', 'Pauline', 'Amandine', 'Nina', 'Caroline', 'Marion', 'Inès', 'Aurélie',
      'Céline', 'Valentine', 'Justine', 'Laurène',
    ],
  },
  georgian: {
    male: [
      'Giorgi', 'Nika', 'Levan', 'Mikhail', 'Zaza', 'David', 'Vakhtang', 'Tengiz',
      'Lasha', 'Gela', 'Shota', 'Beka', 'Edisher', 'Davit', 'Kakhi', 'Zurab', 'Nodar',
      'Koba', 'Lado', 'Elguja', 'Rati', 'Ioseb', 'Bondo', 'Givi', 'Malkhaz', 'Niko',
      'Gela', 'Lasha',
    ],
    female: [
      'Nino', 'Tamar', 'Ketevan', 'Mariam', 'Lali', 'Elene', 'Tina', 'Anano', 'Maka',
      'Natia', 'Khatuna', 'Irina', 'Lika', 'Gvantsa', 'Sopo', 'Natia', 'Nino', 'Elene',
      'Salome', 'Khatuna', 'Tamar', 'Natia', 'Anano', 'Keti', 'Nino', 'Tamar', 'Sopo',
      'Maka',
    ],
  },
  german: {
    male: [
      'Hans', 'Michael', 'Thomas', 'Christian', 'Johannes', 'Peter', 'Andreas',
      'Matthias', 'Jürgen', 'Karl', 'Sebastian', 'David', 'Stefan', 'Uwe', 'Daniel',
      'Frank', 'Rainer', 'Wolfgang', 'Oliver', 'Markus', 'Jan', 'Manfred', 'Bernd',
      'Heinz', 'Armin', 'Klaus', 'Reinhard', 'Jörg', 'Günter', 'Lutz',
    ],
    female: [
      'Anna', 'Laura', 'Julia', 'Sophie', 'Maria', 'Klara', 'Helena', 'Monika',
      'Elisabeth', 'Lisa', 'Hannah', 'Eva', 'Karoline', 'Birgit', 'Andrea', 'Nicole',
      'Melanie', 'Petra', 'Ursula', 'Gabriele', 'Jutta', 'Angela', 'Franziska',
      'Veronika', 'Margarete', 'Sabine', 'Maren', 'Tanja', 'Karin', 'Sonja',
    ],
  },
  greek: {
    male: [
      'Georgios', 'Nikos', 'Dimitrios', 'Andreas', 'Christos', 'Ioannis', 'Vasilis',
      'Konstantinos', 'Panagiotis', 'Spyridon', 'Stavros', 'Anastasios', 'Vangelis',
      'Thanos', 'Evangelos', 'Leonidas', 'Theodoros', 'Apostolos', 'Petros', 'Marios',
      'Makis', 'Kostas', 'Ilias', 'Nikolaos', 'Christodoulos', 'Lefteris', 'Giorgos',
      'Artemios', 'Manolis', 'Xenofon',
    ],
    female: [
      'Maria', 'Elena', 'Katerina', 'Georgia', 'Despina', 'Eleni', 'Vasiliki', 'Anna',
      'Sofia', 'Chrysanthi', 'Eirini', 'Dimitra', 'Fotini', 'Irene', 'Pavlina', 'Vera',
      'Sonia', 'Angeliki', 'Zoe', 'Theodora', 'Kalliopi', 'Dionysia', 'Niki', 'Panagiota',
      'Kalliope', 'Rania', 'Aikaterini', 'Margarita',
    ],
  },
  hebrew: {
    male: [
      'David', 'Daniel', 'Yosef', 'Moses', 'Aaron', 'Isaac', 'Samuel', 'Jacob', 'Elijah',
      'Ethan', 'Benjamin', 'Michael', 'Joseph', 'Joshua', 'Jonathan', 'Noah', 'Zachary',
      'Isaiah', 'Levi', 'Eliezer', 'Adam', 'Solomon', 'Asher', 'Gideon', 'Reuben', 'Nathan',
      'Seth', 'Eliezer', 'Eli',
    ],
    female: [
      'Sarah', 'Rachel', 'Miriam', 'Hannah', 'Leah', 'Esther', 'Deborah', 'Rebecca', 'Ruth',
      'Naomi', 'Jemima', 'Eve', 'Zipporah', 'Shoshana', 'Tamar', 'Michal', 'Hadas', 'Yael',
      'Dina', 'Batya', 'Rivka', 'Michaela', 'Chana', 'Eliana', 'Gila', 'Lila', 'Orli',
      'Liat', 'Adi',
    ],
  },
  hindi: {
    male: [
      'Aarav', 'Vivaan', 'Aditya', 'Vihaan', 'Arjun', 'Reyansh', 'Sai', 'Kabir', 'Ishaan',
      'Rohan', 'Raj', 'Karan', 'Abhishek', 'Siddharth', 'Ansh', 'Suryansh', 'Mohit', 'Rishi',
      'Ayaan', 'Amit', 'Manav', 'Kunal', 'Shivansh', 'Yash', 'Raghav', 'Pranav', 'Harsh',
      'Tanmay', 'Gautam',
    ],
    female: [
      'Aadhya', 'Aanya', 'Isha', 'Sanya', 'Ananya', 'Mira', 'Kavya', 'Nisha', 'Riya',
      'Shruti', 'Pooja', 'Divya', 'Kritika', 'Simran', 'Madhuri', 'Neha', 'Aisha', 'Swara',
      'Rashmi', 'Ishita', 'Naina', 'Tara', 'Kanika', 'Meera', 'Rupal', 'Jasmine', 'Ritika',
      'Sonam', 'Sneha', 'Yamuna',
    ],
  },
  hungarian: {
    male: [
      'László', 'Miklós', 'János', 'István', 'Gábor', 'Zoltán', 'Péter', 'Ferenc', 'Lőrinc',
      'Zsolt', 'Károly', 'Dávid', 'Gergő', 'Sándor', 'Balázs', 'András', 'Ákos', 'Béla',
      'József', 'György', 'Csaba', 'Bence', 'Attila', 'Viktor', 'Dénes', 'Pál', 'Zoltán',
    ],
    female: [
      'Eszter', 'Katalin', 'Judit', 'Mária', 'Zsófia', 'Anna', 'Erzsébet', 'Éva', 'Ildikó',
      'Nóra', 'Gabriella', 'Mónika', 'Krisztina', 'Tünde', 'Piroska', 'Judit', 'Melinda',
      'Dóra', 'Rita', 'Krisztina', 'Hajnalka', 'Lívia', 'Viktória', 'Andrea', 'Ilona',
      'Tímea', 'Mária',
    ],
  },
  indonesian: {
    male: [
      'Rizki', 'Adi', 'Budi', 'Hadi', 'Rifqi', 'Bagus', 'Joko', 'Dimas', 'Hendra', 'Agung',
      'Julius', 'Eko', 'Adit', 'Fajar', 'Rama', 'Yudi', 'Rifai', 'Johan', 'Wahyu', 'Rico',
      'Dani', 'Gilang', 'Andi', 'Slamet', 'Arif', 'Satria', 'Rendra', 'Feri', 'Krisna', 'Yusuf',
    ],
    female: [
      'Dewi', 'Sari', 'Putri', 'Intan', 'Maya', 'Sri', 'Rina', 'Sinta', 'Lestari', 'Novi',
      'Desi', 'Rika', 'Lina', 'Wulan', 'Vera', 'Ayun', 'Melati', 'Citra', 'Lia', 'Nina',
      'Anita', 'Reni', 'Siti', 'Nia', 'Rina', 'Diana', 'Ratna', 'Laila', 'Tina', 'Hani',
    ],
  },
  iraqi: {
    male: [
      'Omar', 'Ali', 'Hassan', 'Ahmad', 'Jassim', 'Khalid', 'Saad', 'Ibrahim', 'Nasser',
      'Hussein', 'Tariq', 'Majid', 'Faisal', 'Yassin', 'Rashed', 'Khalil', 'Abdul', 'Mohammed',
      'Samer', 'Hadi', 'Rami', 'Zaid', 'Rauf', 'Bashar', 'Othman', 'Fadhel', 'Mustafa', 'Nabil',
    ],
    female: [
      'Fatima', 'Aisha', 'Mariam', 'Zainab', 'Hanan', 'Layla', 'Sara', 'Rania', 'Noor',
      'Samira', 'Nadia', 'Amina', 'Huda', 'Yasmin', 'Rita', 'Nermin', 'Hana', 'Ibtisam',
      'Muna', 'Rasha', 'Hiba', 'Rania', 'Asma', 'Khadija', 'Sahar', 'Najwa', 'Sana', 'Lina',
      'Amal',
    ],
  },
  irish: {
    male: [
      'Liam', 'Sean', 'Patrick', 'Conor', 'Brendan', 'Declan', 'Cian', 'Eoghan', 'Oisin',
      'Niall', 'Rory', 'Donal', 'Aidan', 'Darragh', 'Ciaran', 'Cathal', 'Fionn', 'Ruairi',
      'Dermot', 'Eamon', 'Finn', 'Ronan', 'Shane', 'Padraig', 'Colm', 'Phelim', 'Tadhg',
      'Killian', 'Seamus',
    ],
    female: [
      'Aoife', 'Ciara', 'Maeve', 'Siobhan', 'Niamh', 'Aisling', 'Gráinne', 'Orla', 'Fiona',
      'Saoirse', 'Eimear', 'Caoimhe', 'Róisín', 'Mairead', 'Sinead', 'Aileen', 'Deirdre',
      'Bríd', 'Nuala', 'Laoise', 'Clodagh', 'Megan', 'Mairead', 'Aine', 'Ruth', 'Alva',
      'Colleen', 'Sorcha', 'Eilis',
    ],
  },
  italian: {
    male: [
      'Giuseppe', 'Antonio', 'Giovanni', 'Francesco', 'Matteo', 'Alessandro', 'Stefano',
      'Marco', 'Paolo', 'Luigi', 'Roberto', 'Giorgio', 'Vincenzo', 'Andrea', 'Nicola',
      'Enrico', 'Alberto', 'Luca', 'Fabio', 'Gabriele', 'Domenico', 'Cristiano', 'Tommaso',
      'Mario', 'Gianluca', 'Giulio', 'Raffaele', 'Salvatore', 'Daniele',
    ],
    female: [
      'Giulia', 'Maria', 'Francesca', 'Alessandra', 'Giovanna', 'Sofia', 'Valentina',
      'Chiara', 'Elena', 'Laura', 'Serena', 'Martina', 'Angela', 'Ilaria', 'Franca', 'Teresa',
      'Grazia', 'Lucia', 'Claudia', 'Caterina', 'Eleonora', 'Marta', 'Patrizia', 'Silvia',
      'Beatrice', 'Rossella', 'Carla', 'Federica', 'Vera', 'Sara',
    ],
  },
  japanese: {
    male: [
      'Hiroshi', 'Yuki', 'Taro', 'Kenji', 'Takeshi', 'Masato', 'Shinji', 'Kazuya', 'Ryo',
      'Satoshi', 'Kenta', 'Takumi', 'Yoshihiro', 'Tatsuya', 'Naoki', 'Hidetaka', 'Kei',
      'Shinichi', 'Akira', 'Jiro', 'Sho', 'Hiroki', 'Kazuhiro', 'Ryu', 'Yusuke', 'Tomo',
      'Koichi', 'Makoto', 'Nobu',
    ],
    female: [
      'Yuki', 'Sakura', 'Yumi', 'Aiko', 'Hana', 'Mio', 'Haruka', 'Rina', 'Kana', 'Akiko',
      'Natsuki', 'Sayo', 'Miyu', 'Asuka', 'Erika', 'Mayumi', 'Nanako', 'Keiko', 'Yuina',
      'Mika', 'Tomoko', 'Sayaka', 'Rie', 'Riko', 'Eri', 'Ayumi', 'Kumi', 'Miyuki', 'Nana',
      'Chiaki',
    ],
  },
  kannada: {
    male: [
      'Ravi', 'Prakash', 'Rajesh', 'Suresh', 'Ganesh', 'Ramesh', 'Vijay', 'Manoj', 'Mahesh',
      'Kumar', 'Narendra', 'Shankar', 'Ashok', 'Chandrashekar', 'Keshav', 'Raghav', 'Satish',
      'Venkatesh', 'Siddharth', 'Yash', 'Srinivas', 'Naveen', 'Deepak', 'Raghavendra', 'Bharath',
      'Gopal', 'Siddhartha', 'Praveen',
    ],
    female: [
      'Lakshmi', 'Vani', 'Suma', 'Rekha', 'Anita', 'Shilpa', 'Meena', 'Manjula', 'Sunitha',
      'Sangeeta', 'Usha', 'Rupa', 'Sujatha', 'Swathi', 'Kavitha', 'Jaya', 'Deepa', 'Vijaya',
      'Rashmi', 'Shanta', 'Nandini', 'Archana', 'Anuradha', 'Pooja', 'Lalitha', 'Kiran',
      'Sangeetha', 'Hema', 'Prathiba', 'Geetha', 'Sowmya',
    ],
  },
  kazakh: {
    male: [
      'Aibek', 'Ermek', 'Nursultan', 'Daulet', 'Kairat', 'Marat', 'Bauyrzhan', 'Yerbolat',
      'Serik', 'Samat', 'Azamat', 'Rustem', 'Talgat', 'Rinat', 'Aset', 'Daniyar', 'Mukhtar',
      'Kenzhebek', 'Ualikhan', 'Saken', 'Nurlan', 'Yermek', 'Zhanibek', 'Serikzhan',
      'Kuandyk', 'Abay', 'Temirlan', 'Kyzylbek',
    ],
    female: [
      'Aigerim', 'Altyn', 'Gulnar', 'Dina', 'Zhanar', 'Zaira', 'Gulbanu', 'Aisulu', 'Adilya',
      'Gaukhar', 'Zhanat', 'Gulnara', 'Ainur', 'Medea', 'Karina', 'Bayan', 'Sholpan', 'Aigerim',
      'Nurzhan', 'Gulzat', 'Ainagul', 'Lazzat', 'Gulzhan', 'Raisa', 'Aida', 'Gulzhanat', 'Amina',
      'Mariya', 'Asel', 'Gulzhan', 'Zhenis', 'Aruzhan',
    ],
  },
  khmer: {
    male: [
      'Sopheak', 'Rithy', 'Sokha', 'Vannak', 'Mony', 'Vichea', 'Phanna', 'Srey', 'Ratanak',
      'Kosal', 'Dara', 'Sina', 'Chan', 'Sophal', 'Vann', 'Pich', 'Rin', 'Lida', 'Chann',
      'Narin', 'Vuthy', 'Kimsan', 'Visal', 'Rong', 'Kanika', 'Som', 'Tep', 'Meas', 'Sarey', 'Sok',
    ],
    female: [
      'Srey', 'Sokha', 'Phalla', 'Vannary', 'Rachana', 'Chenda', 'Mey', 'Sreymom', 'Kunthea',
      'Sophal', 'Chanthou', 'Saran', 'Sreymey', 'Rithy', 'Pich', 'Dara', 'Sreypov', 'Nita',
      'Kanika', 'Mara', 'Pav', 'Sopha', 'Nary', 'Vannary', 'Reaksmey', 'Sreyneang', 'Rachana',
      'Bopha', 'Kanha', 'Vanna', 'Chantrea',
    ],
  },
  korean: {
    male: [
      'Joon', 'Minho', 'Hyun', 'Seok', 'Jae', 'Sung', 'Ki', 'Dongho', 'Jin', 'Kwang', 'Soo',
      'Hoon', 'Jong', 'Tae', 'Sang', 'Young', 'Hae', 'Chul', 'Jun', 'Nam', 'Yoon', 'Jung',
      'Bum', 'Hee', 'Jaeho', 'Taek', 'Myung', 'Woo', 'Jinwoo',
    ],
    female: [
      'Jin', 'Soo', 'Minji', 'Jisoo', 'Yuna', 'Seulgi', 'Jiwon', 'Haeun', 'Nari', 'Eunji',
      'Sora', 'Hana', 'Jieun', 'Yujin', 'Soojin', 'Kyung', 'Hyo', 'Jung', 'Mina', 'Jiho',
      'Seoyeon', 'Nari', 'Yumi', 'Jiwon', 'Eun', 'Mi', 'Sooah', 'Hee', 'Jina', 'Suji',
    ],
  },
  kyrgyz: {
    male: [
      'Aibek', 'Ernazar', 'Bektur', 'Almaz', 'Nursultan', 'Talas', 'Askar', 'Akmat', 'Yrysbek',
      'Bekbolot', 'Jengish', 'Kubat', 'Marat', 'Dastan', 'Omar', 'Nurzhan', 'Sabyr', 'Sherzod',
      'Tynchtyk', 'Ormonbek', 'Kalyk', 'Baitur', 'Shamil', 'Samad', 'Kurmanbek', 'Murat',
      'Samat', 'Kairat', 'Chyngyz',
    ],
    female: [
      'Aisuluu', 'Gulnara', 'Bota', 'Aigerim', 'Zhanar', 'Ainura', 'Gulzat', 'Nurgul', 'Altynai',
      'Zhanibek', 'Aida', 'Gulsina', 'Saniya', 'Shirin', 'Mira', 'Kumush', 'Naila', 'Gulzhan',
      'Sashira', 'Erkin', 'Aziza', 'Sardana', 'Rita', 'Asel', 'Kamilya', 'Sairagul', 'Nura',
      'Zulfiya', 'Saida', 'Sariyya',
    ],
  },
  'latin-american-spanish': {
    male: [
      'José', 'Carlos', 'Juan', 'Luis', 'Miguel', 'Francisco', 'Antonio', 'Jorge', 'Manuel',
      'Pedro', 'Alejandro', 'Diego', 'David', 'Andrés', 'Rafael', 'Hugo', 'Ricardo', 'Roberto',
      'Eduardo', 'Arturo', 'Luis', 'Fernando', 'Carlos', 'Miguel Ángel', 'Antonio', 'Juan Carlos',
      'Pedro', 'Santiago', 'Iván', 'Óscar',
    ],
    female: [
      'María', 'Ana', 'Carmen', 'Isabella', 'Sofía', 'Laura', 'Paola', 'Gabriela', 'Valentina',
      'Andrea', 'Daniela', 'Carla', 'Cristina', 'Patricia', 'Alejandra', 'Elena', 'Claudia',
      'Verónica', 'Adriana', 'Nancy', 'Victoria', 'Juliana', 'Natalia', 'Vanessa', 'Lorena',
      'Mónica', 'Ángela', 'Diana', 'Teresa', 'Catalina',
    ],
  },
  latvian: {
    male: [
      'Jānis', 'Andris', 'Artūrs', 'Aigars', 'Edgars', 'Mārtiņš', 'Uģis', 'Rihards', 'Miks',
      'Raivis', 'Juris', 'Valdis', 'Armands', 'Gints', 'Raitis', 'Igors', 'Dainis', 'Normunds',
      'Jāzeps', 'Oskars', 'Aivars', 'Gunārs', 'Edvards', 'Zigmārs', 'Voldemārs', 'Juris',
      'Andris', 'Pēteris', 'Gundars', 'Māris',
    ],
    female: [
      'Samantha', 'Anita', 'Ieva', 'Laura', 'Elīna', 'Dace', 'Rita', 'Maija', 'Kristīne',
      'Zanda', 'Jana', 'Līga', 'Inese', 'Marija', 'Lāsma', 'Marta', 'Gunita', 'Irēna', 'Rasma',
      'Laima', 'Daina', 'Zanda', 'Ilze', 'Vija', 'Māra', 'Sanita', 'Anda', 'Biruta', 'Līga',
      'Aija', 'Helēna',
    ],
  },
  lithuanian: {
    male: [
      'Mantas', 'Dainius', 'Vytautas', 'Andrius', 'Tomas', 'Rimantas', 'Giedrius', 'Arūnas',
      'Algirdas', 'Darius', 'Edgaras', 'Julius', 'Ričardas', 'Mindaugas', 'Saulius', 'Arūnas',
      'Gintaras', 'Kazys', 'Vaidotas', 'Jovitas', 'Gediminas', 'Leonardas', 'Vytenis',
      'Romualdas', 'Ignas', 'Marius', 'Tautvydas', 'Dainius', 'Antanas', 'Raimondas',
    ],
    female: [
      'Asta', 'Rūta', 'Agnė', 'Diana', 'Eglė', 'Neringa', 'Jurgita', 'Irena', 'Violeta',
      'Inga', 'Jolanta', 'Giedrė', 'Rasa', 'Edita', 'Laima', 'Dalia', 'Monika', 'Danutė',
      'Skaistė', 'Zita', 'Milda', 'Danguolė', 'Giedrė', 'Neringa', 'Lina', 'Ieva', 'Živilė',
      'Sigutė', 'Birutė', 'Raimonda', 'Gabija',
    ],
  },
  macedonian: {
    male: [
      'Aleksandar', 'Nikola', 'Stefan', 'Vladimir', 'Dimitar', 'Goran', 'Igor', 'Petar',
      'Toni', 'Vasko', 'Dejan', 'Zoran', 'Riste', 'Mihajlo', 'Lazar', 'Bojan', 'Vladimir',
      'Borce', 'Goran', 'Blagoj', 'Kire', 'Dani', 'Mirko', 'Zlatko', 'Sasho', 'Nikolce',
      'Jovan', 'Simo', 'Rade', 'Branislav',
    ],
    female: [
      'Marija', 'Jana', 'Vesna', 'Elena', 'Biljana', 'Katerina', 'Ana', 'Sanja', 'Ljupka',
      'Neda', 'Mirjana', 'Rada', 'Ivana', 'Dragana', 'Sonja', 'Gordana', 'Dijana', 'Radmila',
      'Tatjana', 'Maja', 'Ivana', 'Ljubica', 'Silvana', 'Bojana', 'Biljana', 'Maja', 'Jovana',
      'Tanja', 'Milica', 'Katarina',
    ],
  },
  malay: {
    male: [
        'Ahmad', 'Mohd', 'Amin', 'Ismail', 'Hisham', 'Zainal', 'Abdul', 'Razak', 'Nizam', 'Azman',
        'Hasan', 'Faizal', 'Kamal', 'Haris', 'Sulaiman', 'Jamal', 'Farid', 'Nordin', 'Khairul', 'Yusof',
        'Zul', 'Imran', 'Azhari', 'Aziz', 'Jamal', 'Rafiq', 'Syafik', 'Luqman', 'Hakim', 'Fahmi',
    ],
    female: [
        'Siti', 'Aisyah', 'Nur', 'Fatimah', 'Hanim', 'Rina', 'Zainab', 'Salsabila', 'Nadira', 'Laila',
        'Fatin', 'Amira', 'Hana', 'Ainul', 'Sabrina', 'Rina', 'Siti', 'Laila', 'Yana', 'Maya', 'Farah',
        'Nina', 'Wani', 'Aida', 'Hana', 'Yuslina', 'Khadijah', 'Aishah', 'Nura', 'Syaima',
    ],
},
'mexican-spanish': {
    male: [
        'José', 'Luis', 'Juan', 'Francisco', 'Carlos', 'Miguel', 'Jorge', 'Alejandro', 'Roberto', 'Manuel',
        'Sergio', 'Antonio', 'David', 'Guillermo', 'Raúl', 'Fernando', 'Arturo', 'Héctor', 'Oscar', 'Ricardo',
        'Rafael', 'Andrés', 'Víctor', 'Enrique', 'Julio', 'Marco', 'Ramón', 'José Luis', 'Emanuel', 'Daniel',
    ],
    female: [
        'María', 'Juana', 'Ana', 'Gabriela', 'Isabel', 'Sofía', 'Laura', 'Paola', 'Claudia', 'Jessica',
        'Mariana', 'Fernanda', 'Andrea', 'Diana', 'Vero', 'Lupita', 'Alejandra', 'Verónica', 'Rosalía', 'Teresa',
        'Rosa', 'Silvia', 'Carmen', 'Elena', 'Sonia', 'Angélica', 'Leticia', 'Mónica', 'Natalia', 'Vicky',
    ],
},
moldovan: {
    male: [
        'Ion', 'Vasile', 'Mihai', 'Andrei', 'Nicolae', 'Stefan', 'Doru', 'Emil', 'George', 'Ionut', 'Radu',
        'Gheorghe', 'Paul', 'Marius', 'Tudor', 'Adrian', 'Victor', 'Florin', 'Rares', 'Sorin', 'Cristian',
        'Sebastian', 'Nelu', 'Alexandru', 'Mircea', 'Valentin', 'Silvian', 'Ovidiu', 'Vasile', 'Cornel',
    ],
    female: [
        'Maria', 'Ana', 'Elena', 'Irina', 'Gabriela', 'Larisa', 'Andreea', 'Diana', 'Mihaela', 'Cristina',
        'Valentina', 'Oana', 'Florina', 'Sofia', 'Roxana', 'Simona', 'Nicoleta', 'Lavinia', 'Raluca', 'Monica',
        'Ioana', 'Daniela', 'Teodora', 'Denisa', 'Adina', 'Raluca', 'Mariana', 'Carmen', 'Magdalena', 'Liliana',
    ],
},
mongolian: {
    male: [
        'Bat-Erdene', 'Tuvshinzaya', 'Baatar', 'Munkhbayar', 'Ganbat', 'Jargal', 'Bayar', 'Bold', 'Enkhbat',
        'Amar', 'Naran', 'Erdenebat', 'Sukhbaatar', 'Dulguun', 'Baatar', 'Gantulga', 'Naranbaatar', 'Tsogt',
        'Khishig', 'Ganbaatar', 'Erdene', 'Baatar', 'Nimble', 'Munkh', 'Dulguun', 'Munkhtur', 'Rinchin',
    ],
    female: [
        'Tsetseg', 'Uugan', 'Sarnai', 'Altantuya', 'Bat-Ulzii', 'Oyungerel', 'Gantsetseg', 'Dulmaa', 'Sukhbaatar',
        'Bujin', 'Enkhjargal', 'Tsolmon', 'Erdenechimeg', 'Mongolyn', 'Dolgion', 'Munkhzul', 'Narantuya',
        'Bayarmaa', 'Oyunbileg', 'Jargal', 'Borkh', 'Tsolmon', 'Munkh', 'Sarnai', 'Gulnar', 'Chuluun',
        'Bat-Erdene', 'Saruul', 'Amar', 'Shine',
    ],
},
'moroccan-arabic': {
    male: [
        'Mohamed', 'Ahmed', 'Ali', 'Hassan', 'Omar', 'Youssef', 'Rachid', 'Abdelkader', 'Mustapha', 'Said',
        'Karim', 'Nabil', 'Hicham', 'Jamal', 'Abdelaziz', 'Rachid', 'Fouad', 'Khalid', 'Mustapha', 'Yassir',
        'Tariq', 'Salim', 'Farid', 'Othmane', 'Jalal', 'Imane', 'Nour', 'Jamil', 'Younes', 'Khalil',
    ],
    female: [
        'Fatima', 'Khadija', 'Aicha', 'Laila', 'Zineb', 'Asmaa', 'Naima', 'Samira', 'Nadia', 'Salma', 'Yasmine',
        'Rania', 'Amina', 'Sanaa', 'Nour', 'Siham', 'Najat', 'Hiba', 'Rachida', 'Meriem', 'Hana', 'Mouna',
        'Kenza', 'Zahra', 'Hassna', 'Latifa', 'Jamila', 'Ikram', 'Mouna', 'Dounia',
    ],
},
'new-zealand-english': {
    male: [
        'Liam', 'Noah', 'Jack', 'James', 'Oliver', 'Lucas', 'Mason', 'Ethan', 'Aiden', 'Benjamin', 'Alexander',
        'Henry', 'William', 'Thomas', 'Max', 'Isaac', 'Leo', 'George', 'Harry', 'Samuel', 'Charlie', 'Jackson',
        'Leo', 'Ethan', 'Jacob', 'Joseph', 'Ollie', 'Samuel', 'Daniel', 'Mason',
    ],
    female: [
        'Olivia', 'Charlotte', 'Ava', 'Isla', 'Mia', 'Amelia', 'Ella', 'Sophia', 'Isabella', 'Harper', 'Evelyn',
        'Grace', 'Lily', 'Ruby', 'Zoe', 'Chloe', 'Emily', 'Sophie', 'Aria', 'Maddison', 'Scarlett', 'Layla',
        'Hannah', 'Avery', 'Zara', 'Lila', 'Luna', 'Maya', 'Nora', 'Isla',
    ],
},
norwegian: {
    male: [
        'Ole', 'Lars', 'Per', 'Jan', 'Kristian', 'Anders', 'Magnus', 'Johan', 'André', 'Eirik', 'Mikkel',
        'Håkon', 'Sindre', 'Fredrik', 'Oskar', 'Nicolai', 'Tore', 'Henrik', 'Leif', 'Stein', 'Tor', 'Espen',
        'Are', 'Kjell', 'Einar', 'Sverre', 'Morten', 'Stian', 'Simen', 'Arne',
    ],
    female: [
        'Anna', 'Maria', 'Ingrid', 'Sofie', 'Kari', 'Elise', 'Sara', 'Nora', 'Hanna', 'Maja', 'Synnøve',
        'Amalie', 'Ragnhild', 'Martine', 'Gry', 'Vilde', 'Julie', 'Frida', 'Oda', 'Eline', 'Helene', 'Solveig',
        'Kristin', 'Maren', 'Karoline', 'Irene', 'Hilde', 'Siri', 'Elin',
    ],
},
oromo: {
    male: [
        'Mulugeta', 'Hassan', 'Mohammed', 'Jemal', 'Abdi', 'Yusuf', 'Ali', 'Bashir', 'Mohammed', 'Ahmed',
        'Jibril', 'Hussein', 'Mahmud', 'Mohammed', 'Abdullahi', 'Yasin', 'Abdurrahman', 'Ali', 'Ibrahim',
        'Sadik', 'Mustafa', 'Haji', 'Tadesse', 'Ismail', 'Nuri', 'Mohammed', 'Musa', 'Amar', 'Hamza', 'Said',
    ],
    female: [
        'Mariam', 'Fatima', 'Amina', 'Hawa', 'Sofia', 'Zainab', 'Samsam', 'Khadija', 'Ruqiya', 'Nadia', 'Yasmin',
        'Asha', 'Hanan', 'Roqiya', 'Sumaya', 'Sadia', 'Leila', 'Nasra', 'Iman', 'Nura', 'Muna', 'Rosa', 'Rawa',
        'Halima', 'Saba', 'Rukia', 'Mahi', 'Rania', 'Fatuma', 'Ruqaya',
    ],
},
pashto: {
    male: [
        'Ahmad', 'Mohammad', 'Jamil', 'Omar', 'Khalid', 'Zahir', 'Rashid', 'Naseem', 'Javed', 'Faisal',
        'Hussain', 'Zubair', 'Ali', 'Habib', 'Salman', 'Mujahid', 'Shafqat', 'Naeem', 'Ibrahim', 'Mohsin',
        'Rauf', 'Nasir', 'Sikandar', 'Gulzar', 'Sheraz', 'Arif', 'Sadiq', 'Qasim', 'Rafiq',
    ],
    female: [
        'Fatima', 'Zainab', 'Aisha', 'Samina', 'Shazia', 'Nargis', 'Gulzar', 'Nazia', 'Huma', 'Amina', 'Khadija',
        'Saira', 'Fariha', 'Razia', 'Meher', 'Rubina', 'Sajida', 'Noreen', 'Kiran', 'Haleema', 'Nargis', 'Sadia',
        'Nazia', 'Shahnaz', 'Rukhsar', 'Nadia', 'Asma', 'Mariam', 'Nazia', 'Samina',
    ],
},
polish: {
    male: [
        'Jan', 'Andrzej', 'Marek', 'Piotr', 'Tomasz', 'Krzysztof', 'Michał', 'Paweł', 'Jakub', 'Szymon',
        'Grzegorz', 'Ryszard', 'Adam', 'Jacek', 'Rafał', 'Mieczysław', 'Zbigniew', 'Marcin', 'Wojciech',
        'Witold', 'Roman', 'Kamil', 'Dariusz', 'Radosław', 'Artur', 'Łukasz', 'Sławomir', 'Jarosław',
        'Krzysztof', 'Sebastian',
    ],
    female: [
        'Anna', 'Maria', 'Katarzyna', 'Agnieszka', 'Ewa', 'Joanna', 'Małgorzata', 'Zofia', 'Barbara', 'Iwona',
        'Natalia', 'Beata', 'Monika', 'Halina', 'Grażyna', 'Renata', 'Elżbieta', 'Dorota', 'Alicja', 'Krystyna',
        'Marta', 'Danuta', 'Lucyna', 'Jadwiga', 'Teresa', 'Sylwia', 'Ewa', 'Karolina', 'Magdalena', 'Marzena',
    ],
},
portuguese: {
    male: [
        'João', 'Miguel', 'Pedro', 'José', 'António', 'Francisco', 'Manuel', 'Carlos', 'Rui', 'Sérgio', 'Paulo',
        'André', 'Ricardo', 'Nuno', 'Jorge', 'Tiago', 'Bruno', 'Filipe', 'Vítor', 'Hugo', 'Pedro', 'Leonardo',
        'Gonçalo', 'Daniel', 'Martim', 'Eduardo', 'Marcos', 'Guilherme', 'Rodrigo', 'Samuel',
    ],
    female: [
        'Maria', 'Ana', 'Sofia', 'Joana', 'Beatriz', 'Marta', 'Patrícia', 'Isabel', 'Teresa', 'Diana', 'Inês',
        'Rita', 'Catarina', 'Sandra', 'Carla', 'Cláudia', 'Sara', 'Lúcia', 'Francisca', 'Cristina', 'Susana',
        'Daniela', 'Raquel', 'Liliana', 'Rosa', 'Andreia', 'Bruna', 'Sílvia', 'Elisa', 'Alice',
    ],
},
romanian: {
    male: [
        'Ion', 'Andrei', 'Gabriel', 'Mihai', 'Alexandru', 'Vasile', 'Florin', 'Stefan', 'Radu', 'Ionut', 'George',
        'Paul', 'Cristian', 'Daniel', 'Marian', 'Lucian', 'Marius', 'Victor', 'Sorin', 'Gheorghe', 'Petru',
        'Emil', 'Nicolae', 'Ilie', 'Mihai', 'Aurel', 'Liviu', 'Radu', 'Virgil', 'Cătălin',
    ],
    female: [
        'Maria', 'Ana', 'Elena', 'Ioana', 'Gabriela', 'Andreea', 'Monica', 'Roxana', 'Adriana', 'Nicoleta',
        'Cristina', 'Laura', 'Raluca', 'Simona', 'Carmen', 'Stefania', 'Liliana', 'Irina', 'Madalina', 'Daniela',
        'Anca', 'Iulia', 'Georgiana', 'Florentina', 'Alina', 'Oana', 'Raluca', 'Lorena', 'Lavinia', 'Mariana',
    ],
},
russian: {
    male: [
        'Ivan', 'Dmitry', 'Alexei', 'Sergey', 'Andrei', 'Vladimir', 'Nikolai', 'Mikhail', 'Yuri', 'Roman',
        'Vasily', 'Igor', 'Pavel', 'Konstantin', 'Oleg', 'Boris', 'Viktor', 'Denis', 'Yevgeny', 'Alexandr',
        'Artem', 'Maxim', 'Valery', 'Yaroslav', 'Aleksei', 'Gleb', 'Stanislav', 'Timur', 'Dmitriy', 'Stepan',
    ],
    female: [
        'Maria', 'Anastasia', 'Elena', 'Irina', 'Tatiana', 'Olga', 'Natalia', 'Ekaterina', 'Svetlana', 'Alina',
        'Daria', 'Yulia', 'Nadezhda', 'Galina', 'Victoria', 'Sofia', 'Ludmila', 'Marina', 'Kseniya', 'Alyona',
        'Elizaveta', 'Inna', 'Vera', 'Ksenia', 'Oksana', 'Yana', 'Larisa', 'Valeria', 'Zinaida', 'Nina',
    ],
},
serbian: {
    male: [
        'Nikola', 'Marko', 'Aleksandar', 'Jovan', 'Stefan', 'Milan', 'Nemanja', 'Vuk', 'Dejan', 'Vladimir',
        'Igor', 'Bojan', 'Zoran', 'Radovan', 'Bojan', 'Miroslav', 'Luka', 'Mihailo', 'Nenad', 'Dusan', 'Goran',
        'Dušan', 'Savo', 'Veselin', 'Mladen', 'Branislav', 'Nebojsa', 'Dragan', 'Zlatko', 'Radivoje',
    ],
    female: [
        'Ana', 'Jelena', 'Marija', 'Ivana', 'Milica', 'Sanja', 'Dragana', 'Ksenija', 'Tamara', 'Ljiljana',
        'Maja', 'Tatjana', 'Nada', 'Ivana', 'Radmila', 'Mina', 'Danijela', 'Svetlana', 'Jovana', 'Biljana',
        'Zorica', 'Slavica', 'Dragana', 'Mirjana', 'Aleksandra', 'Nataša', 'Marina', 'Snežana', 'Vesna', 'Slađana',
    ],
},
slovak: {
    male: [
        'Martin', 'Jozef', 'Pavol', 'Marek', 'Juraj', 'Peter', 'Miroslav', 'Andrej', 'Rastislav', 'Richard',
        'Igor', 'Milan', 'Marek', 'Tomáš', 'Daniel', 'Ladislav', 'Stanislav', 'Ján', 'Lukáš', 'Štefan', 'Mário',
        'Jozef', 'Radoslav', 'Róbert', 'Petr', 'Vladimir', 'Jozef', 'Erik', 'Marcel', 'Filip',
    ],
    female: [
        'Anna', 'Maria', 'Jana', 'Mária', 'Eva', 'Katarína', 'Monika', 'Lucia', 'Veronika', 'Gabriela', 'Andrea',
        'Lenka', 'Petra', 'Simona', 'Martina', 'Iveta', 'Monika', 'Jana', 'Natalia', 'Terézia', 'Renáta', 'Hana',
        'Zuzana', 'Mária', 'Nina', 'Radka', 'Sonia', 'Viera', 'Michaela', 'Adriana', 'Klára',
    ],
},
slovenian: {
    male: [
        'Luka', 'Matej', 'Marko', 'Jure', 'Mitja', 'Andrej', 'Igor', 'Žiga', 'David', 'Tomaž', 'Aleš', 'Robert',
        'Jan', 'Darko', 'Damjan', 'Milan', 'Rok', 'Goran', 'Sandi', 'Jani', 'Vladimir', 'Gregor', 'Bojan',
        'Urban', 'Vid', 'Klemen', 'Aljoša', 'Samo', 'Tine', 'Benedikt',
    ],
    female: [
        'Ana', 'Maja', 'Nina', 'Ivana', 'Tina', 'Urška', 'Katarina', 'Maruša', 'Nina', 'Ksenija', 'Nina', 'Jasna',
        'Jana', 'Polona', 'Sanja', 'Maja', 'Silva', 'Jelena', 'Alenka', 'Dajana', 'Eva', 'Teja', 'Monika',
        'Lidija', 'Marta', 'Ana', 'Urška', 'Maja', 'Sanja', 'Nina',
    ],
},
somali: {
    male: [
        'Mohamed', 'Ahmed', 'Abdi', 'Ali', 'Mohammed', 'Yusuf', 'Hassan', 'Omar', 'Farah', 'Nur', 'Ismail',
        'Khalid', 'Mohammed', 'Musa', 'Mohamud', 'Said', 'Jibril', 'Abdirahman', 'Ali', 'Ibrahim', 'Samir',
        'Hassan', 'Abdi', 'Abdirahman', 'Mohammed', 'Farah', 'Ismail', 'Yasin', 'Salah', 'Nur',
    ],
    female: [
        'Amina', 'Fatima', 'Hawa', 'Mariam', 'Zahra', 'Asha', 'Khadija', 'Nadia', 'Sofia', 'Samira', 'Hana',
        'Ruqiya', 'Nura', 'Iman', 'Amina', 'Sadia', 'Ruqiya', 'Faduma', 'Siham', 'Muna', 'Leila', 'Yasmin',
        'Rosa', 'Samiya', 'Sadia', 'Nadifa', 'Amina', 'Hawa', 'Sahra', 'Mariam',
    ],
},
spanish: {
    male: [
        'José', 'Antonio', 'Francisco', 'Manuel', 'Juan', 'David', 'Francisco', 'Javier', 'José Antonio',
        'Daniel', 'Carlos', 'Miguel', 'Alejandro', 'Sergio', 'Pedro', 'Ángel', 'Juan Carlos', 'Rafael', 'Luis',
        'Antonio', 'Ricardo', 'Oscar', 'Ramón', 'Jesús', 'Fernando', 'Mario', 'Álvaro', 'Adrián', 'Rubén',
        'Guillermo',
    ],
    female: [
        'María', 'Carmen', 'Isabel', 'Ana', 'Laura', 'Sofía', 'Juana', 'Teresa', 'Pilar', 'Mónica', 'Mercedes',
        'Paola', 'Natalia', 'Cristina', 'Rosa', 'Marina', 'Diana', 'Gabriela', 'Elena', 'Inés', 'Beatriz',
        'Carla', 'Lorena', 'Silvia', 'Andrea', 'Nerea', 'Ángeles', 'Vera', 'Raquel', 'Patricia', 'Alejandra',
    ],
},
swahili: {
    male: [
        'Juma', 'Abdi', 'Hassan', 'Musa', 'Ibrahim', 'Khalid', 'Ali', 'Jabir', 'Omar', 'Mahmud', 'Faraji', 'Nuru',
        'Amani', 'Jafari', 'Khamis', 'Kassim', 'Maalim', 'Mohamed', 'Abubakar', 'Adamu', 'Mosi', 'Rashid', 'Harun',
        'Msa', 'Yusuf', 'Jibril', 'Said',
    ],
    female: [
        'Fatima', 'Aisha', 'Zainab', 'Hawa', 'Maryam', 'Sofia', 'Amina', 'Ruqiya', 'Nadia', 'Leila', 'Hafsa', 'Iman',
        'Sadia', 'Rukia', 'Mariam', 'Halima', 'Asha', 'Sera', 'Yasmin', 'Rosa', 'Zahara', 'Sumaya', 'Nura', 'Zawadi',
        'Amani', 'Fatuma', 'Rukiya', 'Mina', 'Siri',
    ],
},
swedish: {
    male: [
        'Erik', 'Lars', 'Anders', 'Oskar', 'Johan', 'Carl', 'Fredrik', 'Magnus', 'Henrik', 'Nils', 'Gustav', 'Stefan',
        'Martin', 'Rikard', 'Alexander', 'Björn', 'Mattias', 'Sven', 'Daniel', 'Ola', 'Jens', 'Tomas', 'Samuel', 'Håkan',
        'Per', 'Jan', 'Lennart', 'Joakim', 'Bo', 'Sigvard',
    ],
    female: [
        'Anna', 'Eva', 'Maria', 'Sara', 'Karin', 'Elisabeth', 'Johanna', 'Linda', 'Frida', 'Sofia', 'Amanda', 'Cecilia',
        'Gunilla', 'Helena', 'Mia', 'Katrin', 'Emilie', 'Matilda', 'Ulrika', 'Ylva', 'Annelie', 'Monica', 'Elin', 'Nina',
        'Carina', 'Ida', 'Birgitta', 'Veronica',
    ],
},
tagalog: {
    male: [
        'José', 'Antonio', 'Carlos', 'Manuel', 'Juan', 'Pedro', 'Luis', 'Francisco', 'José María', 'Emilio', 'Ricardo',
        'Rafael', 'Eduardo', 'Gabriel', 'Alfredo', 'Fernando', 'Juanito', 'Mario', 'Oscar', 'Alejandro', 'Marlon', 'Raul',
        'Arthur', 'Ernesto', 'Santiago', 'Elpidio', 'Vincente', 'Eusebio', 'Javier',
    ],
    female: [
        'Maria', 'Ana', 'Carmen', 'Isabel', 'Sofia', 'Juana', 'Teresa', 'Pilar', 'Luz', 'Nina', 'María Clara', 'Lourdes',
        'Rosa', 'Pia', 'Cristina', 'Eva', 'Imelda', 'Natividad', 'Rosalinda', 'Gloria', 'Alma', 'Celina', 'Marilou',
        'Helen', 'Violeta', 'Nerissa', 'Luisa', 'Julieta', 'Rita', 'Felicitas',
    ],
},
tajik: {
    male: [
        'Jumakhon', 'Rustam', 'Mumin', 'Shamsuddin', 'Davlat', 'Rakhmat', 'Said', 'Zafar', 'Ismoil', 'Daler', 'Farid',
        'Gulmurod', 'Sherali', 'Olim', 'Nemat', 'Rahmat', 'Jamshed', 'Javlon', 'Shukur', 'Alisher', 'Sattor', 'Bakhodir',
        'Rakhmon', 'Gulrukh', 'Khayrullo', 'Sodik', 'Arbob', 'Husniddin', 'Iskandar', 'Umed',
    ],
    female: [
        'Zarina', 'Shirin', 'Gulnora', 'Marhabo', 'Shabnam', 'Nilufar', 'Rukhshona', 'Dilnoza', 'Lola', 'Siyoma', 'Mavluda',
        'Khadija', 'Gulbahor', 'Dilorom', 'Nargis', 'Musharraf', 'Rohila', 'Sultan', 'Samira', 'Nasiba', 'Marifat',
        'Dilbar', 'Oydin',
    ],
},
tamil: {
    male: [
        'Arun', 'Kumar', 'Raj', 'Gopal', 'Suresh', 'Ravi', 'Sankar', 'Muthu', 'Vijay', 'Murugan', 'Rajesh', 'Venkatesh',
        'Karthik', 'Srinivasan', 'Senthil', 'Sathish', 'Balaji', 'Hari', 'Sivakumar', 'Mohan', 'Ramesh', 'Karthikeyan',
        'Selvan', 'Rajendran', 'Vijayakumar', 'Arunachalam', 'Saravanan', 'Ganesan',
    ],
    female: [
        'Anjali', 'Kavitha', 'Priya', 'Sridevi', 'Lakshmi', 'Aishwarya', 'Rajeshwari', 'Shanthi', 'Deepa', 'Meena', 'Swathi',
        'Rukmini', 'Sujatha', 'Jyothi', 'Saroja', 'Usharani', 'Kanmani', 'Vijayalakshmi', 'Sangeetha', 'Chitra', 'Malathi',
        'Aruna', 'Hema', 'Rukmani', 'Nandini', 'Radhika', 'Anitha', 'Gayathri',
    ],
},
tatar: {
    male: [
        'Ramil', 'Ilmar', 'Marat', 'Faris', 'Aidar', 'Dinar', 'Rustam', 'Timur', 'Ildar', 'Salavat', 'Rinat', 'Artem',
        'Nail', 'Rafael', 'Kamil', 'Renat', 'Dmitry', 'Rustem', 'Ilgiz', 'Farit', 'Shamil', 'Azat', 'Rishat', 'Zakir',
        'Yusup', 'Arslan', 'Gumer', 'Emil',
    ],
    female: [
        'Gulnaz', 'Aigul', 'Elvira', 'Liliya', 'Raisa', 'Zulfiya', 'Dina', 'Linar', 'Nailya', 'Saniya', 'Amina', 'Aisylu',
        'Svetlana', 'Leila', 'Zarina', 'Saira', 'Dilara', 'Gulnara', 'Alsu', 'Gulya', 'Lilia', 'Galiya', 'Zara', 'Yulia',
        'Elmira',
    ],
},
thai: {
    male: [
        'Nattapong', 'Somchai', 'Wisit', 'Prasert', 'Chakrit', 'Kittisak', 'Surasak', 'Sontaya', 'Burin', 'Sakda',
        'Somkid', 'Krit', 'Tinnakorn', 'Suthin', 'Naris', 'Krisada', 'Ari', 'Sakchai', 'Tanakorn', 'Jirawat', 'Anupong',
        'Kittipong', 'Tanawat', 'Pongpanu', 'Apichart', 'Nattawut', 'Wichai', 'Viroj',
    ],
    female: [
        'Sukanya', 'Nattaya', 'Kanya', 'Somjai', 'Pattama', 'Siriporn', 'Suwannee', 'Apinya', 'Yupa', 'Orasa', 'Kritsana',
        'Wassana', 'Rungnapa', 'Nipa', 'Panida', 'Nantana', 'Kanjana', 'Jariya', 'Naraporn', 'Benjawan', 'Kanya',
        'Tanyaporn', 'Chalita', 'Rachada', 'Huong',
    ],
},
tigrinya: {
    male: [
        'Haben', 'Tekle', 'Girmay', 'Asmelash', 'Zeresenay', 'Yohannes', 'Berhane', 'Mekonnen', 'Tesfay', 'Kidane',
        'Haile', 'Kahsay', 'Tesfom', 'Asfaha', 'Gebremedhin', 'Fikre', 'Merhawi', 'Negasi', 'Weldeab', 'Amare',
        'Kebede', 'Gebre', 'Tsehaye', 'Araya', 'Wolde', 'Tewelde', 'Berihu', 'Teklay', 'Yemane', 'Fiseha',
    ],
    female: [
        'Aster', 'Mulu', 'Senait', 'Almaz', 'Meskerem', 'Saba', 'Luwam', 'Selam', 'Mariam', 'Rahel', 'Fiyori', 'Mehret',
        'Hanna', 'Miriam', 'Betty', 'Haymanot', 'Hirut', 'Zebib', 'Eden', 'Mekdes', 'Makda', 'Eleni', 'Abrehet',
        'Lidya', 'Selamawit', 'Blen', 'Helen', 'Seble', 'Mihret', 'Tigist',
    ],
},
turkish: {
    male: [
        'Ahmet', 'Mehmet', 'Mustafa', 'Ali', 'Hasan', 'Hüseyin', 'İsmail', 'Kadir', 'Mahmut', 'Oğuz', 'Burak', 'Emre',
        'Mert', 'Ege', 'Yusuf', 'Okan', 'Ömer', 'Arda', 'Serkan', 'Uğur', 'Hakan', 'Deniz', 'Mücahit', 'Cem', 'Yavuz',
        'Gökhan', 'Eren', 'Enes', 'Kaan', 'Müslim',
    ],
    female: [
        'Fatma', 'Ayşe', 'Emine', 'Zeynep', 'Elif', 'Meryem', 'Seda', 'Sibel', 'Aylin', 'Özge', 'Gül', 'Derya', 'İrem',
        'Hande', 'Pelin', 'Neslihan', 'Gizem', 'Melike', 'Merve', 'Aysel', 'Rabia', 'Büşra', 'Ece', 'Bahar', 'Cemre',
    ],
},
turkmen: {
    male: [
        'Myrat', 'Berdimuhammed', 'Nury', 'Gurbangeldy', 'Sapar', 'Annageldy', 'Atamyrat', 'Begench', 'Azat', 'Serdar',
        'Bayram', 'Aman', 'Rejep', 'Allaberdi', 'Yusup', 'Yhlas', 'Durdy', 'Saparmurat', 'Ogulberdi', 'Nuryagdy', 'Batyr',
        'Chary', 'Maksat', 'Geldimyrat', 'Bashim', 'Bekmyrat', 'Kurbannazar', 'Yoldash', 'Aly', 'Nazar',
    ],
    female: [
        'Gulnabat', 'Oguljemal', 'Aynabat', 'Shirin', 'Bibi', 'Aybike', 'Myratgul', 'Gulbahar', 'Mahriban', 'Perizat',
        'Akmaya', 'Dursun', 'Amangul', 'Mekan', 'Gulruh', 'Zuhra', 'Kerimgul', 'Nurjemal', 'Gulbanu', 'Aylar', 'Jeren',
        'Gulnara', 'Aida', 'Solmaz', 'Sakina', 'Gulendam', 'Shatlyk', 'Yasemen', 'Ogulgerek', 'Gulzira',
    ],
},
ukrainian: {
    male: [
        'Ivan', 'Viktor', 'Dmytro', 'Oleh', 'Mykola', 'Andriy', 'Serhiy', 'Volodymyr', 'Yevhen', 'Oleksandr', 'Roman',
        'Sergiy', 'Vladimir', 'Taras', 'Artem', 'Yaroslav', 'Danylo', 'Vasyl', 'Oleksiy', 'Pavlo', 'Yuriy', 'Stepan',
        'Oleg', 'Vitaliy', 'Ihor', 'Maxym', 'Vadym', 'Bohdan',
    ],
    female: [
        'Olena', 'Natalia', 'Oksana', 'Iryna', 'Tetiana', 'Yulia', 'Svitlana', 'Kateryna', 'Valeria', 'Irina', 'Nadiya',
        'Mariana', 'Larysa', 'Viktoriya', 'Liliya', 'Diana', 'Sofia', 'Lesya', 'Anastasia', 'Yevheniya', 'Vira', 'Zhanna',
        'Ira', 'Tamara',
    ],
},
urdu: {
    male: [
        'Ahsan', 'Raza', 'Bilal', 'Zain', 'Faisal', 'Hamza', 'Shahbaz', 'Imran', 'Aqeel', 'Qasim', 'Kamran', 'Farhan',
        'Naveed', 'Saad', 'Asif', 'Rizwan', 'Azhar', 'Usman', 'Tariq', 'Kashif', 'Nadeem', 'Shahid', 'Adeel', 'Sikandar',
        'Fahad', 'Junaid', 'Irfan', 'Adnan', 'Zubair', 'Mansoor',
    ],
    female: [
        'Ayesha', 'Sana', 'Khadija', 'Fatima', 'Zara', 'Hina', 'Sadia', 'Nida', 'Samina', 'Hira', 'Rabia', 'Mehwish',
        'Uzma', 'Zainab', 'Nadia', 'Fariha', 'Bushra', 'Iqra', 'Lubna', 'Mariam', 'Rimsha', 'Farah', 'Asma', 'Nimra',
        'Tahira', 'Anum', 'Shazia', 'Anam', 'Sahar', 'Saima',
    ],
},
uzbek: {
    male: [
        'Aziz', 'Javlon', 'Rustam', 'Nodir', 'Doston', 'Rashid', 'Farhod', 'Ismail', 'Jahongir', 'Otabek', 'Mukhammad',
        'Sanjar', 'Sherzod', 'Alisher', 'Rakhmat', 'Gulomjon', 'Said', 'Bakhodir', 'Bekzod', 'Daler', 'Zafar', 'Odin',
        'Asad', 'Sardor', 'Tursun', 'Umid', 'Jasur', 'Anvar', 'Kamil',
    ],
    female: [
        'Gulnara', 'Dilshoda', 'Makhliyo', 'Saniya', 'Shirin', 'Feruza', 'Zuhra', 'Oygul', 'Mavluda', 'Tugul', 'Sodira',
        'Nilufar', 'Makhbuba', 'Zarina', 'Gulbahor', 'Shahnoza', 'Dilbar', 'Dildora', 'Mariam', 'Khalima', 'Munisa',
        'Gulnoza', 'Sukhrob', 'Rukhshona',
    ],
},
vietnamese: {
    male: [
        'Nguyen', 'Anh', 'Bao', 'Duc', 'Hanh', 'Khanh', 'Huy', 'Khoa', 'Long', 'Manh', 'Nam', 'Phong', 'Quan', 'Son',
        'Tam', 'Tuan', 'Vinh', 'Xuan', 'Vu', 'Thanh', 'Hieu', 'Chien', 'Nhat', 'Linh', 'Thinh', 'Quang', 'Ngan',
    ],
    female: [
        'Lan', 'Mai', 'Thao', 'Ngoc', 'Phuong', 'Hoa', 'Linh', 'Chi', 'Tuyet', 'Dao', 'Thuy', 'Nhung', 'Quyen', 'Hanh',
        'Tam', 'Bao', 'Ha', 'Thu', 'Mi', 'Hue', 'Ngan', 'Trang', 'An', 'Kieu', 'Tram',
    ],
},
wolof: {
    male: [
        'Moussa', 'Amadou', 'Cheikh', 'Samba', 'Ibrahima', 'Babacar', 'Ousmane', 'Diop', 'Mamadou', 'Ndiaye', 'Lamine',
        'Serigne', 'Alioune', 'Pape', 'Moustapha', 'Malick', 'Demba', 'Youssou', 'Khadim', 'Mame', 'Fadel', 'Bocar',
        'Seydou', 'Abdoulaye', 'Modou', 'Souleymane', 'Mbaye', 'Doudou',
    ],
    female: [
        'Fatou', 'Awa', 'Mariama', 'Ndèye', 'Seynabou', 'Khadija', 'Adama', 'Ndeye', 'Aminata', 'Maimouna', 'Bineta',
        'Astou', 'Bintou', 'Fatim', 'Aissatou', 'Diama', 'Yacine', 'Diarra', 'Sokhna', 'Daba', 'Sira', 'Dior', 'Nafissatou',
        'Diouma', 'Moussa', 'Rokhaya', 'Adji', 'Kiné', 'Balde', 'Coumba',
    ],
},
zulu: {
    male: [
        'Siphiwe', 'Thandolwethu', 'Sibusiso', 'Mandla', 'Thulani', 'Lungelo', 'Bongani', 'Nkosinathi', 'Vusumuzi', 'Themba',
        'Mfundo', 'Khulekani', 'Siboniso', 'Melusi', 'Phumlani', 'Sandile', 'Nkosingiphile', 'Zwelakhe', 'Njabulo',
        'Siyabonga', 'Mxolisi', 'Mthokozisi', 'Sipho', 'Lungisani', 'Thokozani', 'Sanele', 'Zwelithini', 'Mduduzi',
        'Nkanyezi', 'Siyanda',
    ],
    female: [
        'Thandeka', 'Nomvula', 'Nomthandazo', 'Nokuthula', 'Nqobile', 'Nombulelo', 'Nosipho', 'Sinenhlanhla', 'Nonhlanhla',
        'Zandile', 'Nokubonga', 'Ayanda', 'Nombuso', 'Sibongile', 'Khanyisile', 'Zanele', 'Gugu', 'Phumzile', 'Ntombifuthi',
        'Lindiwe', 'Thuli', 'Nonjabulo', 'Nondumiso', 'Thobile', 'Nokwanda', 'Bongi', 'Sindi', 'Nobuhle', 'Ntokozo',
    ],
},
  
}