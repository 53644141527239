import { SET_VOICES } from './types'
import voiceApi from '../../services/voice.service'
import { AUTHORS_NAMES } from '../../utils/authorsNames'

export const setVoices = payload => {
  return { type: SET_VOICES, payload }
}

export const getVoicesList = () => {
  return dispatch => {
    return voiceApi
      .getVoices()
      .then(data => {
        let counter = 0
        let language = ''
        let gender = ''
        const voicesWithNames = data.data.map(voice=>{
          if (language === voice.language.toLowerCase().split(' ').join('-')) {
            if (voice.gender.toLowerCase() === gender) {
              const changedVoice = {...voice, voiceName: AUTHORS_NAMES[language.toLocaleLowerCase()][voice.gender.toLowerCase()][counter]}
            
              ++counter
              return changedVoice
            } else {
              gender = voice.gender.toLowerCase()
              counter = 0
              const changedVoice = {...voice, voiceName: AUTHORS_NAMES[language.toLocaleLowerCase()][voice.gender.toLowerCase()][counter]}
              ++counter
              return changedVoice
            }
            
          } else {
            language = voice.language.toLowerCase().split(' ').join('-')
            counter = 0
            gender = voice.gender.toLowerCase()
            const changedVoice = {...voice, voiceName: AUTHORS_NAMES[language.toLocaleLowerCase()][voice.gender.toLowerCase()][counter]}
            ++counter
            return changedVoice
          }
        })
        console.log(voicesWithNames)
        dispatch(setVoices(voicesWithNames))
      })

      .catch(err => {
        console.error(err)
      })
  }
}
